// Resize Sensor will ensure the sticky sidebar is able to update itself when the browser is resized
import 'css-element-queries/src/ResizeSensor.js';
import StickySidebar from 'sticky-sidebar/dist/sticky-sidebar.js';

export class StickySidebarComponent {
    topMargin: number;
    bottomMargin: number;
    breakpoint: number;
    stickySidebar: any;
    navContainer: HTMLElement;
    accountForNavigation: boolean;
    sidebar: HTMLElement;
    initialSidebarHeight: number;
    constructor(sidebar: HTMLElement) {
        this.topMargin = parseInt(sidebar.getAttribute('data-margin-top'));
        this.bottomMargin = parseInt(sidebar.getAttribute('data-margin-bottom'));
        this.breakpoint = parseInt(sidebar.getAttribute('data-sticky-for'));
        this.updateStickySidebar = this.updateStickySidebar.bind(this);
        this.navContainer = document.getElementById('nav-container');
        this.accountForNavigation = false;
        this.sidebar = sidebar;
        this.initialSidebarHeight = this.sidebar.querySelector("[data-sticky-inner]").getBoundingClientRect().height;
        this.stickySidebar = new StickySidebar(
            `#${this.sidebar.id}`,
            {
                containerSelector: '[data-sticky-container]',
                innerWrapperSelector: '[data-sticky-inner]',
                topSpacing: this.topMargin,
                bottomSpacing: this.bottomMargin,
                minWidth: this.breakpoint
            }
        );

        // Update it after a beat, in case anything needs to finish loading
        window.requestAnimationFrame(() => {
            this.updateStickySidebar();
        });
        window.addEventListener('scroll', () => {
            window.requestAnimationFrame(() => {
                if (this.navContainer) {
                    (this.navIsShowingStatus) ? this.sidebar.classList.add('account-for-nav') : this.sidebar.classList.remove('account-for-nav');
                    if (this.initialSidebarHeight !== this.sidebar.getBoundingClientRect().height) {
                        //Accounts for price rule alerts being dismissed that will then change the height of the sidebar
                        this.updateStickySidebar();
                    }
                }
            });
        });
    }

    get navIsShowingStatus() {
        return this.navContainer.getAttribute('data-nav-is-showing') === 'true' && this.stickySidebar.direction === "up" && this.stickySidebar.affixedType !== "STATIC" && this.stickySidebar.affixedType !== false && this.stickySidebar.affixedType !== "CONTAINER-BOTTOM" && window.innerWidth >= this.stickySidebar.options.minWidth;
    }

    updateStickySidebar() {
        if (this.stickySidebar) {
            this.stickySidebar.updateSticky();
        }
    }
}
