import { AddressModal } from '../../modules/address-modal';

export const profilePage = () => {
    const sectionButtons = [].slice.call(document.querySelectorAll('[data-section]')) as HTMLButtonElement[];
    if (sectionButtons.length > 0) {
        sectionButtons.forEach(sectionButton => {
            sectionButton.addEventListener('click', toggleSectionInputs)
        })
    }
    const hiddenSectionValue = <HTMLInputElement>document.getElementById("hidden-section-type");
    const hiddenUpdateStatus = <HTMLInputElement>document.getElementById("profile-update-fail");

    // If error is thrown my MVC model state
    const hiddenfailedProperty = <HTMLInputElement>document.getElementById("profile-update-fail-property");

    if (hiddenUpdateStatus && hiddenUpdateStatus.value.toLowerCase() == "true") {
        //MVC MODEL STATE FAILURE
        if (hiddenfailedProperty !== null && hiddenfailedProperty.value != "") {
            const failedFormPropertyElement = document.querySelectorAll(`[name="${hiddenfailedProperty.value}"]`);
            if (failedFormPropertyElement) {
                const failedComponent = (<HTMLInputElement>failedFormPropertyElement[0]).closest("[data-info-group]");
                const triggerButton = failedComponent.querySelector('[data-section]');
                triggerEvent(triggerButton, "click");
            }
            //TESSITURA FAILURE
        } else if (hiddenSectionValue.value != "") {
            const section = document.querySelectorAll(`[data-section="${hiddenSectionValue.value}"]`)[0];
            triggerEvent(section, "click");
        }

    }

    document.addEventListener('DOMContentLoaded', function (event) {
        document.querySelector(".validation-summary-errors")?.scrollIntoView({ behavior: 'smooth' });
    });


    const phoneEditButtons = document.querySelectorAll('[data-phone-number]');
    phoneEditButtons.forEach(editButton => editButton.addEventListener('click', choosePhoneNumber));

    const modals = Array.prototype.slice.call(document.querySelectorAll('[data-profile-address-modal]')) as HTMLElement[];
    modals.forEach(modal => {
        new AddressModal(modal);
    });
}

const choosePhoneNumber = (e) => {
    const modalTargetId = e.currentTarget.getAttribute('data-a11y-dialog-show');
    const phoneNumber = e.currentTarget.getAttribute('data-phone-number');
    const phoneId = e.currentTarget.getAttribute('data-phone-id');
    const phoneTypeId = e.currentTarget.getAttribute('data-phone-type');

    const modal = document.getElementById(modalTargetId);

    const phoneNumberInput = <HTMLInputElement>modal.querySelector('#EditPhoneRequest_PhoneNumber');
    phoneNumberInput.value = phoneNumber;

    const phoneIdInput = <HTMLInputElement>modal.querySelector('#EditPhoneRequest_PhoneId');
    phoneIdInput.value = phoneId;

    const phoneTypeIdInput = <HTMLInputElement>modal.querySelector('#EditPhoneRequest_PhoneTypeId');
    phoneTypeIdInput.value = phoneTypeId;
}

const triggerEvent = (element: Element, eventName) => {
    const event = new Event(eventName);
    element.dispatchEvent(event);
}
const toggleSectionInputs = (e) => {
    const sectionID = e.currentTarget.getAttribute('data-section');
    const hiddenSectionValue = (<HTMLInputElement>document.getElementById("hidden-section-type"));
    const activeEdit = e.currentTarget.getAttribute('aria-expanded') === 'true';
    const sectionInfo = document.querySelectorAll(`[data-section-info="${sectionID}"]`);
    const sectionInputs = document.querySelectorAll(`[data-section-input="${sectionID}"]`);
    const sectionInputsSave = document.querySelectorAll(`[data-section-input-save="${sectionID}"]`);

    if (hiddenSectionValue)
        hiddenSectionValue.value = sectionID;

    if (sectionInfo.length > 0) {
        sectionInfo[0].setAttribute('aria-hidden', (!activeEdit).toString());
    }

    if (sectionInputs.length > 0) {
        sectionInputs[0].setAttribute('aria-hidden', activeEdit.toString());
        setTimeout(() => {
            if (sectionInputs[0].querySelector('input'))
                sectionInputs[0].querySelector('input').focus()
        }, 10);
    }

    if (sectionInputsSave.length > 0) {
        sectionInputsSave[0].setAttribute('aria-hidden', activeEdit.toString());
    }

    e.currentTarget.setAttribute('aria-expanded', (!activeEdit));

    if (activeEdit) {
        e.currentTarget.innerText = e.currentTarget.getAttribute('data-edit-text');
    } else {
        e.currentTarget.innerText = e.currentTarget.getAttribute('data-cancel-text');
    }

    //Modifiers of the DOM
    if (e.currentTarget.hasAttribute('data-trigger-secondary-btn-class'))
        e.currentTarget.classList.toggle('btn-outline');

    
        
}