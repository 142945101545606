import AddressApi from '../../modules/address-api';

export const registerPage = () => {
    const countryDropdown = <HTMLSelectElement>document.getElementById('UpdateProfileRequest_SelectedCountryId');
    const stateDropdown = <HTMLSelectElement>document.getElementById('UpdateProfileRequest_SelectedStateCode');

    if (countryDropdown && stateDropdown) {
        countryDropdown.addEventListener('change', (e) => { updateStateDropdown(e, stateDropdown); })
    }
}

const updateStateDropdown = (e, stateDropDown) => {
    const selectedCountry = e.target.value;
    AddressApi.getStatesForCountry(+selectedCountry).then(states => {
        stateDropDown.innerHTML = '';
        if (states.length) {
            states.forEach(state => {
                var option = document.createElement('option');
                option.value = state.stateCode.toString();
                option.innerHTML = state.description;
                stateDropDown.appendChild(option);
            });
            stateDropDown.disabled = false;
        }
        else {
            stateDropDown.disabled = true;
        }
    })
}
