
export class Alert {
    alertElement: HTMLElement;

    constructor(alertElement: HTMLElement, container?: HTMLElement) {
        if (alertElement) {
            this.alertElement = alertElement;

            alertElement.querySelector('[data-close]').addEventListener('click', () => {
                this.alertElement.style.display = 'none';
                this.alertElement.classList.add('hide');
            })
        }
    }
}