import { donationInterruptPage } from './pages/donationInterruptPage/donationInterruptPage';
import { headerMenu } from './modules/header-menu';
import { Alert } from './modules/ace-alert';
import { blockClicksOnLoad } from './modules/block-clicks-on-load';
import { showHidePassword } from './modules/show-hide-password';
import { selectPerformance } from './pages/exchangesPage/exchangesPage-selectPerformance';
import { selectTickets } from './pages/exchangesPage/exchangesPage-selectTickets';
import { quickDonate } from './pages/quickDonate/quickDonatePage';
import { profilePage } from './pages/profilePage/profilePage';
import { confirmationPage } from './pages/confirmationPage/confirmationPage'
import { registerPage } from './pages/registerPage/registerPage'
import CartTimer from './modules/cart-timer';
import { SvgSprite } from './modules/svg-loader';
import { Modal } from './modules/a11y-modal';
import { StateDropdown } from './modules/stateDropdown';

import './modules/promo-interface';
import { StickySidebarComponent } from './modules/sticky-sidebar-component';

// SVG Sprite Inject
new SvgSprite('/Static/img/adage-sprite.svg');
new CartTimer();
donationInterruptPage();
profilePage();
headerMenu();
//aceModal();
blockClicksOnLoad();
showHidePassword();
selectPerformance();
selectTickets();
quickDonate();
confirmationPage();
registerPage();

const modals = Array.prototype.slice.call(document.querySelectorAll('[data-modal]')) as HTMLElement[];
modals.forEach(modal => {
    new Modal(modal);
});

const alerts = Array.prototype.slice.call(document.querySelectorAll('[data-alert]')) as HTMLElement[];
alerts.forEach(alert => {
    new Alert(alert);
});
const stateDropdown = document.querySelector('#State') as HTMLSelectElement;
const countryDropdown = document.querySelector('#Country') as HTMLSelectElement;
if (stateDropdown && countryDropdown) {
    new StateDropdown(stateDropdown, countryDropdown);
}

const stickyContainerEls = [].slice.call(document.querySelectorAll('[data-sticky-el]')) as HTMLElement[];
stickyContainerEls.forEach(stickyEl => {
    new StickySidebarComponent(stickyEl);
})