import { bodyLock, bodyUnlock } from '../helpers/bodyLock';

export class AceLoader {
    loaderElement: HTMLElement;
    bodyElement: HTMLElement;
    bodyStyles: string;
    constructor(loaderElement?: HTMLElement) {
        this.bodyElement = document.getElementsByTagName("body")[0] as HTMLElement;
        if (loaderElement) {
            this.loaderElement = loaderElement;
        } else {
            var defaultLoader = document.getElementsByClassName('ace-loader')[0];

            if (!defaultLoader) {
                console.log("Could not find ace-loader on this page");
            } else {
                this.loaderElement = (<HTMLElement>defaultLoader);
            }
        }

    }

    public show = () => {
        this.loaderElement.style.display = "";
        this.bodyStyles = bodyLock();
    };

    public hide = () => {
        this.loaderElement.style.display = "none";
        bodyUnlock('');
    }

}