import { AddressModalFormFields, AddressConfig, StateSummary } from '../classes/address';
import { AceLoader } from './ace-loader';
import AddressApi from './address-api';

export class AddressModal {
    modalElement: HTMLElement;
    loader: AceLoader;
    invalidFields: Array<string>;
    otherValidationNotes: Array<string>;
    validationSummary: HTMLElement;
    fields: AddressModalFormFields;
    addressConfig: AddressConfig;

    constructor(modalElement: HTMLElement, container?: HTMLElement) {
        this.modalElement = modalElement;
        this.loader = new AceLoader();
        this.addressConfig = (window as any).pageData as AddressConfig;
        this.validationSummary = (<HTMLElement>this.modalElement.querySelector('.validation-summary-errors'));

        let saveAddressButtons = modalElement.querySelectorAll('[data-save-btn]');
        if (saveAddressButtons != null) {
            for (var i = 0; i < saveAddressButtons.length; i++) {
                saveAddressButtons[i].addEventListener('click', this.saveAddressForm.bind(this));
            }
        }

        // Populates based off keys of class, which match the name attributes of the HTML form elements
        this.fields = new AddressModalFormFields();
        this.fields = Object.getOwnPropertyNames(this.fields).reduce((acc, currKey) => {
            return {
                ...acc,
                [currKey]: this.modalElement.querySelector(`[name=${currKey}`)
            }
        }, {}) as AddressModalFormFields;

        const country = this.fields.country;
        country.addEventListener('change', this.getStatesByCountry.bind(this));

        this.validateField.bind(this);
    }

    saveAddressForm = (e) => {
        e.preventDefault();
        let saveAddressButtons = this.modalElement.querySelectorAll('[data-save-btn]');
        if (saveAddressButtons != null)
        {
            (saveAddressButtons[0] as HTMLButtonElement).disabled = true;
            saveAddressButtons[0].classList.add('btn-spinning');
        }
        this.resetValidationSummary();
        if (this.validate()) {
            const formValues = Object.keys(this.fields).reduce((obj, key) => {
                return {
                    ...obj,
                    [key]: this.fields[key]?.value
                }
            }, {}) as { [name: string]: string };

            const isPrimary = formValues.isPrimary?.toLowerCase() === 'true';
            const isShipping = formValues.isShipping?.toLowerCase() === 'true';

            this.loader.show();
            return AddressApi.updateAddress(
                +formValues.id,
                formValues.firstName,
                formValues.lastName,
                formValues.address1,
                formValues.address2,
                formValues.city,
                +formValues.state,
                formValues.zipCode,
                +formValues.country,
                formValues.phone,
                isPrimary,
                isShipping
            ).then(() => {
                window.location.reload();
            }).then(() => {
                // pre-selects the relevant delivery method that the user just added a shipping address for
                const createdNewShippingAddress = isShipping && (isPrimary || formValues.id === '-1');
                window.sessionStorage.setItem('UseDeliveryMethodThatRequiresAddress', createdNewShippingAddress.toString())
            });
        } else {
            if (saveAddressButtons.length > 0) {
                (saveAddressButtons[0] as HTMLButtonElement).disabled = false;
                saveAddressButtons[0].classList.remove('btn-spinning');
            }
            this.displayValidationSummary();
        }
    };

    deleteAddress = (e) => {
        e.preventDefault();
        {
            let id = (<HTMLInputElement>this.modalElement.querySelector('[name=id')).value;

            this.loader.show();
            return AddressApi.removeAddress(+id).then(() => {
                window.location.reload();
            })

        }
    };

    validate = () => {
        return [
            this.validateName,
            this.validateAddress,
            this.validatePhone
        ].every(condition => condition())
    }

    validatePhone = () => {
        // if phone field exists (i.e, primary address modal), then we need to confirm they've entered a valid phone
        if (this.fields?.phone !== null && this.fields?.phone !== undefined) {
            if (!this.validateField(this.fields.phone))
                return false;
        } else {
            // there is no phone number field to validate, so we can say it's valid
            return true;
        }

        const phone = this.fields.phone?.value;
        const selectedCountry = this.fields.country?.selectedOptions[0].text;

        let isValid = true;

        if (selectedCountry === 'USA') {
            if (phone.length !== 10) {
                this.otherValidationNotes.push(this.addressConfig.invalidUsaPhoneNumberErrorMessage)
                isValid = false;
            }
        } else {
            if (phone.length < 10) {
                this.otherValidationNotes.push(this.addressConfig.invalidPhoneLengthErrorMessage);
                isValid = false;
            }
        }

        if (Number.isNaN(Number(phone))) {
            this.otherValidationNotes.push(this.addressConfig.invalidPhoneCharactersErrorMessage)
            isValid = false;
        }

        return isValid;
    }

    validateName = () => {
        const firstName = this.fields.firstName;
        const lastName = this.fields.lastName;

        const nameFieldsNotRequired = (firstName === undefined || firstName === null) &&
                                      (lastName  === undefined || lastName  === null);
        if (nameFieldsNotRequired)
            return true;

        const firstNameValid = this.validateField(firstName);
        const lastNameValid = this.validateField(lastName);

        return firstNameValid && lastNameValid;
    }

    validateAddress = () => {
        let isValid = [
            this.fields.address1,
            this.fields.city,
            this.fields.zipCode,
            this.fields.country
        ].every(this.validateField)

        const stateValid = this.validateField(this.fields.state) || this.fields.state.disabled;

        isValid = isValid && stateValid;

        return isValid;
    }

    validateField = (element: HTMLElement) => {
        if (!element)
            return false;
        let input = element as HTMLInputElement;
        let select = element as HTMLSelectElement;

        if ((input && (!input.value.trim() || !input.value.length)) ||
            (select && (!select.value.trim() || !select.value.length))) {
            const ename = element.attributes['name'].value;
            const label = this.modalElement.querySelector(`[for^=${ename}]`);

            if (label) {
                this.invalidFields.push(label.innerHTML);
            } else {
                this.invalidFields.push(ename);
            }
            return false;
        }
        return true;
    }

    resetValidationSummary = () => {
        if (this.validationSummary) {
            this.validationSummary.innerHTML = '';
            this.validationSummary.classList.add('visually-hidden');
        }
        this.invalidFields = [];
        this.otherValidationNotes = [];
    }

    displayValidationSummary = () => {
        let validationMessage = '';

        if (this.invalidFields.length > 0) {
            validationMessage += 'The following fields are required: ';
            validationMessage += this.invalidFields.join(', ');
            validationMessage += '</br>';
        }

        if (this.otherValidationNotes.length > 0) {
            validationMessage += this.otherValidationNotes.join('</br>');
        }

        if (this.validationSummary) {
            this.validationSummary.innerHTML = validationMessage;
            this.validationSummary.classList.remove('visually-hidden');
        }
    }


    getStatesByCountry = (e: Event) => {
        let country = (<HTMLSelectElement>e.currentTarget).value;
        AddressApi.getStatesForCountry(+country).then(results => {
            this.updateStatesDropDown(results)
        })
    }

    updateStatesDropDown = (states: Array<StateSummary>) => {
        const stateDropDown = this.fields.state;

        stateDropDown.innerHTML = '';
        if (states.length) {
            states.forEach(state => {
                var option = document.createElement('option');
                option.value = state.id.toString();
                option.innerHTML = state.description;
                stateDropDown.appendChild(option);
            });
            stateDropDown.disabled = false;
        }
        else {
            stateDropDown.disabled = true;
        }
    }
}