export interface AddressConfig {
    invalidPhoneLengthErrorMessage: string,
    invalidUsaPhoneNumberErrorMessage: string,
    invalidPhoneCharactersErrorMessage: string
}

export interface StateSummary {
    description: string;

    stateCode: string;

    id: number;

    inactive: boolean;

    country: CountrySummary;
}

export interface CountrySummary {
    description: string;

    id: number;

    inactive: boolean;
}

export interface Address {
    id: number;

    //public Entity AffiliatedConstituent { get; set; }


    //public SalutationTypeSummary AltSalutationType { get; set; }


    //public AddressTypeSummary AddressType { get; set; }


    city: string;


    //public DateTime ? CreatedDateTime { get; set; }


    //public string CreateLocation { get; set; }


    //public string CreatedBy { get; set; }

    //public Entity Constituent { get; set; }


    deliveryPoint: string;


    endDate: Date;


    //public int ? GeoArea { get; set; }


    //public bool ? Inactive { get; set; }


    //public bool ? Label { get; set; }


    //public DateTime ? UpdatedDateTime { get; set; }


    //public string UpdatedBy { get; set; }

    months: string;


    //public int ? NcoaAction { get; set; }


    //public int ? NcoaSession { get; set; }

    postalCode: string;
    postalCodeFormatted: string;
    primaryIndicator: boolean;
    //public DateTime ? StartDate { get; set; }
    state: StateSummary;
    street1: string;
    street2: string;
    street3: string;
    country: CountrySummary;
    phone: string;

    //public bool EditIndicator { get; set; }


    //public bool IsFromAffiliation { get; set; }
}

// Fields are initialized to null so that they can be accessed with Object.getPropertyNamesOf
export class AddressModalFormFields {
    id: HTMLInputElement = null;
    firstName: HTMLInputElement = null;
    lastName: HTMLInputElement = null;
    isPrimary: HTMLInputElement = null;
    isShipping: HTMLInputElement = null;
    address1: HTMLInputElement = null;
    address2: HTMLInputElement = null;
    city: HTMLInputElement = null;
    state: HTMLSelectElement = null;
    zipCode: HTMLInputElement = null;
    country: HTMLSelectElement = null;
    phone: HTMLInputElement = null;
}