import { FormEvent } from "react";
import { removeLoadingSpinner } from "../../../Content/modules/submitLoadingSpinner";

export const confirmationPage = () => {
    var donationSpan = (<HTMLElement>document.querySelector('[data-donation-line]'));
    if (donationSpan != null) {
        var amountSpan = donationSpan.querySelector('[data-value]');

        if (amountSpan.innerHTML === "" && donationSpan.style.display === "") {
            donationSpan.style.display = "none";
        }
    }

    document.addEventListener('DOMContentLoaded', function (event) {
        const confirmationErrorField = document.querySelector(".field-validation-error") as HTMLElement;
        if (confirmationErrorField) {
            document.querySelector(".field-validation-error").scrollIntoView({ behavior: 'smooth' });
        }

        const donationCsiForm = document.getElementById("donation-csi-form") as HTMLFormElement;
        if (donationCsiForm) {
            donationCsiForm.addEventListener("submit", function (event) {
                event.preventDefault();

                const submitButton = donationCsiForm.querySelector('button[type=submit]') as HTMLButtonElement;
                const formErrorMessage = donationCsiForm.querySelector('.form-error-message') as HTMLElement;
                const formSuccessMessage = document.querySelector('.csi-form-success-message') as HTMLElement;
                formErrorMessage.hidden = true;

                const req: CsiDonationRequest = createRequestFromForm(donationCsiForm);

                sendUniqueDonationConfirmationCsi(req).then((response) => {
                    if (!response.ok) {
                        throw new Error(response.statusText);
                    }

                    removeLoadingSpinner(submitButton);
                    donationCsiForm.hidden = true;
                    formSuccessMessage.hidden = false;
                }).catch((error) => {
                    removeLoadingSpinner(submitButton);
                    formErrorMessage.hidden = false;
                })
            });
        }
    });
}

interface CsiDonationRequest {
    ContactMethodID: number;
    CategoryID: number;
    ActivityTypeID: number;
    OriginID: number;
    Notes: string;
    OrderId: number;
}

function createRequestFromForm(form: HTMLFormElement): CsiDonationRequest {
    const req: CsiDonationRequest = {
        ContactMethodID: 0,
        CategoryID: 0,
        ActivityTypeID: 0,
        OriginID: 0,
        Notes: '',
        OrderId: 0,
    };

    const checkedPrompts = [];

    for (let element of Array.from(form.elements)) {
        if (!(element instanceof HTMLInputElement)) {
            continue;
        }

        if (element.type === 'checkbox') {
            if (element.checked) {
                checkedPrompts.push(element.value);
            }
        }
        else {
            req[element.id] = (element as HTMLInputElement).value;
        }
    }

    req.Notes = checkedPrompts.join('\r\n');

    return req;
}

function sendUniqueDonationConfirmationCsi(request: CsiDonationRequest): Promise<Response> {
    const apiUrl = '/api/csi/send-unique-donation-response';

    return fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
    });
}