export default class CartTimer {

    private serverTimeLeft: number;
    private intervalHandle: ReturnType<typeof setTimeout>;
    private container: HTMLElement;
    constructor() {
        let container = document.querySelector('[data-cart-timer]') as HTMLElement;

        this.container = container;

        let remainingTime = container?.getAttribute('data-cart-timer');

        if (!container || !remainingTime) return;


        const startingMinutes = +remainingTime.split(':')[0];
        const startingSeconds = +remainingTime.split(':')[1];

        const startingDate = new Date();
        let secondsRemaining: number = remainingTime
            ? startingDate.setSeconds(startingDate.getSeconds() + (startingMinutes * 60) + startingSeconds)
            : 0;

        if (secondsRemaining) {
            this.serverTimeLeft = secondsRemaining;

            this.tick = this.tick.bind(this);
            this.startCountDown();
            this.intervalHandle;
        }
    }

    calculateSecondsRemaining() {
        return Math.floor((this.serverTimeLeft - new Date().getTime()) / 1000);
    }

    tick() {
        const secondsRemaining = this.calculateSecondsRemaining();

        const min = Math.floor(secondsRemaining / 60);
        const sec = secondsRemaining - (min * 60);

        let secondsString = sec.toString();
        let minutesString = min.toString();

        if (sec < 10 && secondsString.length < 2) {
            secondsString = '0' + secondsString;
        }
        if (min < 10 && minutesString.length < 2) {
            minutesString = '0' + minutesString;
        }

        if (min <= 0 && sec <= 0) {
            clearInterval(this.intervalHandle);
            
            this.container.classList.add('expired');

            // reload page so that cart is cleared from backend
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }

        const minutesSpan = document.querySelector('[data-cart-timer-minutes]');
        const secondsSpan = document.querySelector('[data-cart-timer-seconds]');

        minutesSpan.innerHTML = minutesString;
        secondsSpan.innerHTML = secondsString;
    }

    startCountDown() {
        this.intervalHandle = setInterval(this.tick, 1000);
    }
}